@import 'mixins';

html {
  font-size: 62.5%;
  scroll-behavior: smooth;

  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;

  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;

  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;

  -ms-overflow-style: scrollbar;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 100;
  font-family: $regular-font;

  &[class^='no-scroll-'] {
    overflow: hidden;
    width: 100%;
  }
}

input:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: var(--ria-input-color);
  -webkit-box-shadow: none;
  outline: none;
  transition: background-color 5000s ease-in-out 0s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  box-shadow: none;
  cursor: pointer;
  text-align: center;
  color: inherit;
}
