@import '../../../styles/index.scss';

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .dot1 {
    background-color: black;
    border-radius: 50%;
    width: vw_d(12);
    height: vw_d(12);
    margin: 0 vw_d(4);

    animation: aminationBounce 0.5s linear infinite;
    animation-delay: 0s;
  }
  .dot2 {
    background-color: black;
    border-radius: 50%;
    width: vw_d(12);
    height: vw_d(12);
    margin: 0 vw_d(4);

    animation: aminationBounce 0.5s linear infinite;
    animation-delay: 0.1s;
  }
  .dot3 {
    background-color: black;
    border-radius: 50%;
    width: vw_d(12);
    height: vw_d(12);
    margin: 0 vw_d(4);

    animation: aminationBounce 0.5s linear infinite;
    animation-delay: 0.2s;
  }
}

@keyframes aminationBounce {
  0% {
    margin-bottom: 0;
  }

  50% {
    margin-bottom: vw_d(12);
  }

  100% {
    margin-bottom: 0;
  }
}
